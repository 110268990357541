import { template as template_106f37efb114452c8accf50149f66763 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_106f37efb114452c8accf50149f66763(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
