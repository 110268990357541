import { template as template_d4323fa350024d48b65ce0a23f2b6afa } from "@ember/template-compiler";
const WelcomeHeader = template_d4323fa350024d48b65ce0a23f2b6afa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
